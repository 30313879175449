import * as SignalR from '@microsoft/signalr'

export function myPromise() {
    const myTime: number = 1
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(myTime)
        }, 1000)
    })
}
